import React from "react"
import Layout from "@components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { useLocation } from "@reach/router"

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// コンポーネント呼び出し
import { FaqGraphqlArray } from "@components/graphql-data-faq.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const FaqSearchResults = () => {
  const indexUrl = '/'
  const typeUrl = 'faq/';
  const pageTitle = 'お客様サポート';
  const pageSubTitle = 'FAQ';

  const LinkBeginnerL = indexUrl + typeUrl;
  const LinkBeginnerR = indexUrl + "contact/";
  const wordSearchContent = WordSearch(indexUrl, typeUrl).Faq; // ワード検索Faq

  // graphql-data-post.jsから記事データ呼び出し
  const allPostData = FaqGraphqlArray('', 'search')
  let setArray = allPostData.allData;

  // Seo情報設定
  const pathName = 'search-results';
  const seoTag = SeoContents({ Path:pathName });

  const location = useLocation()
  const urlItem = location.href;
  let item = '#';
  let name;

  let index1, status;
  if ( urlItem !== undefined ){
    index1 = urlItem.indexOf(item);
  } else {
    index1 = -1;
  }

  if (index1 !== -1 ) { // 検索結果ありの場合
    let check = decodeURI(urlItem.substring(index1 + 1));
    name = check; // 検索ワードセット
    let searchWord;
    let resultArray = [];
    for ( let i = 0; i < setArray.length; i++) {
        searchWord = setArray[i].Title + setArray[i].Content;
        if ( searchWord.toLowerCase().indexOf(check.toLowerCase()) !== -1) {
          resultArray.push(setArray[i])
        } 
      }
    setArray = resultArray;
    status = 'loadcomp'
    name = "「" + name + "」の検索結果：" + setArray.length + "件"; // 表示名作成
  } else if ( index1 === -1 ) { // 検索結果がない場合
    name = `検索中`
    status = 'loading'
    setArray = [];
  }

  // 記事一覧生成
  const pickupList = setArray.map((result) => {
    return  <>
              <div className="md:w-1/3 md:aspect-square" key={result.Uri} style={{marginBottom:15 ,padding:10}}>
                <article className="post-list-item !m-0 " style={{ height: '100%' }} itemScope itemType="http://schema.org/Article">
                  <a href={indexUrl + typeUrl + result.Slug + "/"} itemProp="url" className="Campaign w-[100%]">
                    <div className="w-full" style={{ height: '55%' }}>
                      {/* 画像部分 */}
                      <GatsbyImage className="block w-full h-full rounded-blog overflow-hidden" image={result['Image']}
                        alt={result.AltText ? result.AltText : ""} as={`figure`} style={{ aspectRatio: "25/14" , height: '100%' }}
                      />
                    </div>
                    {/* タイトル部分 */}
                    <div className="bg-white w-full text-left" >
                      <p className="CampP md:text-xs text-[15px] font-bold mb-4 py-2 text-left " style={{ height: '8%' }}>{result.Title}</p>
                      <p className="text-[11px] text-left  md:text-[10px]  pb-4" >{parse(result.Excerpt)}</p>
                    </div>
                  </a>
                </article>
              </div>
            </>;
  });

  const fixedItem = FixedFoot( 'nolmal', ''); // 固定フッター

  return (
    <>
			<Layout>
        {seoTag}
        <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
          <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
            <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
              <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">{pageTitle}</h2>
              <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">{pageSubTitle}</h3>
            </div>
          </div>
        </div>
        
        {/* サブメニュー */}
        <div className="bg-[#F6F6F6]">
          <div className="max-w-5xl mx-auto flex px-5">
            <a href={LinkBeginnerL} className="text-center py-4 px-2 md:px-10 w-1/2 bg-white mr-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">
              よくある質問
            </a>
            <a href={LinkBeginnerR} className="text-center py-4 px-2 md:px-10 w-1/2 bg-white ml-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">
              お問い合わせ
            </a>
          </div>
        </div>

        {/* 検索窓 */}
        <div className="max-w-xl mx-auto md:max-w-5xl mt-[30px] px-5">
          <div className="h-20">
						{wordSearchContent}
					</div>
        </div>				 
        
        {/* 記事アーカイブ */}
        <div className="bg-[#F6F6F6] pt-[70px]">
          <div className="max-w-xl mx-auto md:max-w-5xl px-5">
            <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6]">{name}</h3>
            <div className="flex flex-wrap justify-between items-start">
              {pickupList}
            </div>
          </div>  
        </div>
        
        {/* 固定フッター */}
        {fixedItem}
      </Layout>
    </>
  )
}
export default FaqSearchResults